import React, { useRef } from 'react';
import cx from 'classnames';

import { useActivePath, useMobileView, useSiteMetadata } from '../../hooks';
import CTA from '../CTA';
import GifImage from '../../images/bitnimbus.avif';
import * as styles from './styles.module.scss';

const Home = () => {
  const ref = useRef(null);
  useActivePath('#home', ref);
  const data = useSiteMetadata();

  return (
    <section
      id="home"
      className={cx({
        [styles.root]: true,
      })}
    >
      <div className={styles.tagContainer} ref={ref}>
        <div>
          <h1 className={styles.title}>
            Multi-cloud Data Platform For All Developers.
          </h1>
          <h2 className={styles.tagLine}>You Click, We Deploy.</h2>
          <span className={styles.ctaContainer}>
            <CTA
              type="primary"
              label="Sign Up for free"
              external={true}
              link={data.site.siteMetadata.productLink}
            />
            <span className={styles.contactButton}>
              <CTA
                type="default"
                label="Check Pricing >"
                external={false}
                link="/pricing"
              />
            </span>
          </span>
        </div>
      </div>
      <div className={styles.landingImage}>
        <img src={GifImage} alt="platform image" height="100%" width="auto" />
      </div>
    </section>
  );
};

export default Home;
