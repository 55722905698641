import React from 'react';

import * as styles from './styles.module.scss';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Container from '../Container';
interface FeatureCardProps {
  image: React.ReactNode;
  content: string;
  title: string;
}

const FeatureCard = (props: FeatureCardProps) => {
  const { image, title, content } = props;

  return (
    <Container  className={styles.root}>
      <div className={styles.head}>
        {props.image}
        <h3 className={styles.title}>{title}</h3>
      </div>
      <div>
        <div className={styles.content}>
          <p className={styles.description}>{content}</p>
        </div>
      </div>
    </Container>
  );
};

export default FeatureCard;
